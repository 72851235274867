<template>
  <div>
    <div class='md-layout'>
      <div class='md-layout-item'>
        <div class='btn-row'>
          <md-button class='md-success md-size-20 md-middle-size-100' @click='newPlan'>
            {{ $t('button.add') }}
          </md-button>
        </div>
        <md-card>
          <md-card-header class='md-card-header-icon md-card-header-green'>
            <div class='card-icon'>
              <md-icon>work</md-icon>
            </div>
            <h4 class='title'>{{ $t('label.plans') }}</h4>
          </md-card-header>
          <md-card-content>
            <md-table
              :md-sort-fn='customSort'
              :md-sort-order.sync='currentSortOrder'
              :md-sort.sync='currentSort'
              :value='queriedData'
              class='paginated-table table-striped table-hover'
            >
              <md-table-toolbar>
                <md-field>
                  <label for='pages'>{{ $t('label.per_page') }}</label>
                  <md-select v-model='pagination.perPage' name='pages'>
                    <md-option
                      v-for='item in pagination.perPageOptions'
                      :key='item'
                      :label='item'
                      :value='item'
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>

                <md-field>
                  <md-input
                    v-model='searchQuery'
                    :placeholder="$t('label.search_records')"
                    class='mb-3'
                    clearable
                    style='width: 200px'
                    type='search'
                  ></md-input>
                </md-field>
              </md-table-toolbar>

              <md-table-row slot='md-table-row' slot-scope='{item}'>
                <md-table-cell :md-label="$t('plans.plan_name')" md-sort-by='plan_name'>
                  <div class="cell-left-actions">
                    <md-button
                        class="md-simple md-info"
                        @click.stop.prevent="onPlanWorks(item)"
                    >
                      <div style="display: flex; font-size: 0.9rem">
                        <i class="material-icons">edit</i>
                        {{ item.plan_name }}
                      </div>
                    </md-button>
                  </div>

                </md-table-cell>
                <md-table-cell :md-label="$t('plans.asset_type')" md-sort-by='asset_type'>
                  {{ getAssetTypeName(item.asset_type_ids) }}
                </md-table-cell>
                <!--md-table-cell :md-label="$t('plans.maintenance_type')" md-sort-by="maintenance_type">
                  {{ $t(`maintenance_type.${getMaintenanceTypeName(item.maintenance_type_id)}`) }}
                </md-table-cell-->
                <md-table-cell v-if='isShowMucColumn' :md-label="$t('plans.muc_name')">
                  {{ getMucName(item.calc_id) }}
                </md-table-cell>
                <md-table-cell :md-label="$t('plans.subnetwork_name')">
                  {{ getSubnetworkName(item.subnetwork_id) }}
                </md-table-cell>
                <md-table-cell :md-label="$t('plans.budget')">
                  {{ item.budget }}
                </md-table-cell>
                <md-table-cell :md-label="$t('plans.cost_estimation')">
                  <div :class="[isCostOverhead(item.cost_estimation, item.budget)? 'cost-overhead':'cost-good']">
                    {{ item.cost_estimation }}
                  </div>
                </md-table-cell>
                <md-table-cell :md-label="$t('plans.full_cost_estimation')">
                  {{ item.full_cost_estimation }}
                </md-table-cell>
                <md-table-cell :md-label="$t('plans.selected')">
                  <div class='modified-percent'>
                    <md-progress-bar :md-value='calcPercent(item.selected_percent)'
                                     md-mode='determinate'></md-progress-bar>
                    <span>{{ item.selected_percent }}%</span>

                  </div>
                </md-table-cell>
                <md-table-cell :md-label="$t('plans.status')">
                  {{ item.status === 0 ? $t('plans.status_draft') : $t('plans.status_final') }}
                </md-table-cell>
                <!--md-table-cell :md-label="$t('plans.created_at')">
                  {{ item.created_at | toDate }}
                </md-table-cell-->
                <md-table-cell
                  :md-label="$t('tables.actions')"
                  class='btn-cont'
                >
                  <div class='cell-actions'>
                    <md-button
                      class='md-raised md-sm md-primary'
                      @click.stop.prevent='onEditRow(item)'
                    >
                      {{ $t('button.edit') }}
                    </md-button>
                    <md-button
                      class='md-raised md-sm md-danger'
                      @click.stop.prevent='onDelete(item)'
                      :disabled="item.status===1"
                    >
                      {{ $t('button.delete') }}
                    </md-button>

                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <div class='footer-table md-table'>
              <table>
                <tfoot>
                <tr>
                  <th
                    v-for='item in footerTable'
                    :key='item.name'
                    class='md-table-head'
                  >
                    <div
                      class='md-table-head-container md-ripple md-disabled'
                    >
                      <div class='md-table-head-label'>{{ item }}</div>
                    </div>
                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </md-card-content>
          <md-card-actions md-alignment='space-between'>
            <div class>
              <p class='card-category'>
                {{
                  $t('label.showing_from_to_of_entries', {
                    from: to === 0 ? 0 : from + 1,
                    to: to,
                    total
                  })
                }}
              </p>
            </div>
            <pagination
              v-model='pagination.currentPage'
              :per-page='pagination.perPage'
              :total='total'
              class='pagination-no-border pagination-success'
            ></pagination>
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>

import {Pagination} from '@/components'
import Swal from "sweetalert2";
// import {SlideYDownTransition} from 'vue2-transitions'
// import Fuse from 'fuse.js'

export default {
  name: 'maintenance_planning',
  components: {
    Pagination
  },
  data() {
    return {
      isShowMucColumn:false,
      //domain: this.$route.params.domain,
      planList: [],
      plansSelected: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      muc_list: [],
      subnetworks: [],
      assetTypes: [],
      maintenanceTypes: [],
      currentSort: 'key',
      currentSortOrder: 'asc',
      searchQuery: '',
      propsToSearch: ['key'],
      searchedData: [],
      fuseSearch: null,
      footerTable: []
    }
  },
  filters: {
    toDate: function(value) {
      return value.substring(0, 10)
    }
  },
  async beforeRouteUpdate(to, from, next) {
    await this.refreshListData()
    next()
  },
  async created() {
    this.$store.dispatch('LOAD_UNIT_COSTS_LIST').then(res => {
      this.muc_list = [...res]
    })
    this.$store.dispatch('LOAD_SUBNETWORK_LIST').then(res => {
      this.subnetworks = [...res]
    })
    this.$store.dispatch('LOAD_ASSET_TYPE_LIST').then(res => {
      this.assetTypes = [...res]
    })
    this.$store.dispatch('LOAD_MAINTENANCE_TYPE_LIST').then(res => {
      this.maintenanceTypes = [...res]
    })
    await this.refreshListData()
  },
  methods: {
    calcPercent(percent) {
      return Math.round(percent)
    },

    onPlanSelect(items) {
      this.plansSelected = items.map(item => item.plan_id)
    },
    isCostOverhead(cost, budget) {
      return cost > budget
    },
    getMucName(uuid) {
      const muc = this.muc_list.find(item => item.muc_id === uuid)
      return muc ? muc.muc_name : 'undef'
    },
    getSubnetworkName(uuid) {
      const subnetwork = this.subnetworks.find(item => item.subnetwork_id === uuid)
      return subnetwork ? subnetwork.subnetwork_name : 'undef'
    },
    getAssetTypeName(ids) {
      const assetType = this.assetTypes.filter(item => ids.includes(item.id)).map(type => this.$t(`asset_type.${type.name}`)).join(', ')
      return assetType ? assetType : 'undef'
    },
    getMaintenanceTypeName(id) {
      const maintenanceType = this.maintenanceTypes.find(item => item.id === id)
      return maintenanceType ? maintenanceType.name : 'undef'
    },
    async refreshListData() {
      this.planList = await this.$store.dispatch('LOAD_PLAN_LIST')
    },
    newPlan() {
      this.$router.push({name: 'plans-add'})
    },
    onEditRow(item) {
      this.$router.push({name: 'plans-upd', params: {pid: item.plan_id}})
    },

    onDelete(item) {
      const alert = {
        icon: 'success',
        text: this.$t(`label.plan_was_deleted`),
        footer: ''
      }
      Swal.fire({
        title: `${this.$t("label.are_you_sure")}`,
        text: `${this.$t("label.do_you_want_to_delete_plan")} ${item.plan_name}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-danger btn-fill",
        cancelButtonClass: "md-button md-default btn-fill",
        confirmButtonText: `${this.$t("label.yes_delete_it")}`,
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          try {
            await this.$store.dispatch("DELETE_PLAN", item.plan_id)
            const ind = this.planList.findIndex(d => d.plan_id === item.plan_id)
            this.planList.splice(ind, 1)
            Swal.fire({
              title: this.$t("label.deleted"),
              text: this.$t("label.plan_was_deleted"),
              type: "success",
              confirmButtonClass: "md-button md-success btn-fill",
              buttonsStyling: false
            });
          } catch (err) {
            alert.type = 'error'
            alert.text = this.$t(`label.plan_was_not_deleted`)
            alert.footer = this.$t(err.message ? err.message : err)
            Swal.fire(alert)
            //throw err
          }
        }
      });
    },

    onPlanWorks(item) {
      this.$router.push({name: 'plans-elements', params: {pid: Number(item.plan_id)}})
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'asc') {
          return a[sortBy]
            .toString()
            .localeCompare(b[sortBy].toString(), undefined, {numeric: true})
        }
        return b[sortBy]
          .toString()
          .localeCompare(a[sortBy].toString(), undefined, {numeric: true})
      })
    }
  },
  computed: {
    queriedData() {
      const result = this.searchQuery
        ? this.searchedData
          ? this.searchedData
          : []
        : this.tableData
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
    tableData() {
      return this.planList
    }
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      const regex = new RegExp(`${value}`, 'i')
      if (value !== '') {
        result = this.tableData.filter((row) => {
          return Object.values(row).reduce((incl, elem) => {
            return incl || regex.test(elem)
          }, false)
        })
      }
      this.searchedData = result
    }
  }
}

</script>

<style lang='scss' scoped>

.modified-percent {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 5px;

  .md-progress-bar {
    width: 100%;
    margin: 4px 5px;
  }

  span {
    width: 4rem;
  }
}

.cost-overhead {
  color: red;
}

.cost-good {
  color: green;
}

.cell-left-actions {
  display: flex;
  flex-direction: row;
  align-items: center;

  .md-button {
    margin: 3px 0;
    width: unset;
  }
}

.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    margin-left: 10px
  }
}
</style>
